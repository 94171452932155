@import '../../styles/media.scss';

.keen-slider {
	&--brand {
		padding-top: 4em;
		padding-bottom: 6em;
	}
	&--brand-with-heading {
		padding-top: 2em;
		padding-bottom: 6em;
	}
}

.slide-frame {
	// background: rgba(255, 0, 255, 0.3);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	color: #fff;
	font-weight: 600;
	height: 200px;
	max-height: 100vh;
	padding: 0.5em;
}
a.brand-title {
	font-size: 0.9em;
	color: #555;
	margin-top: 10px;
	line-height: 1.1em;
}

.dct-frame {
	padding: 4em;
}
.img-frame {
	padding: 4em;
}
.iia-frame {
	padding: 4em;
}
.ast-frame {
	padding: 4em;
}
// .cbs-frame {

// }
.ltl-frame {
	padding: 4em;
}
.sww-frame {
	padding: 4em;
	
}